import React from "react";
import useTranslations from "../../components/useTranslations";
import Footer from "../../components/Footer/Footer";
import NewsLeftNav from "../../components/TwoColumnLeftNav/NewsLeftNav";
import {graphql} from "gatsby";
import LocalizedLink from "../../components/localizedLink";

const News = ({ data: { allMdx } }) => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const {
		allNews,
		newProducts,
		campaign,
		products,
		events,
		notice,
		general,
	} = useTranslations();
	return (
		<>
			<article className="news-page two-column-left-nav">
				<NewsLeftNav />
				<section className="main-content">
					<div className="content-container">
						<h1 className={"large bold"}>{allNews}</h1>
						<ul className="post-list flex between wrap">
							{allMdx.edges.map(({ node: post }) => (
								<>
									{post.frontmatter.tag === "exhibition" ? (
										<LocalizedLink
											key={`${post.frontmatter.title}-${post.fields.locale}`}
											className={`news-link`}
											to={`/news/exhibition/`}
										>
											<li
												key={`${post.frontmatter.title}-${post.fields.locale}`}
											>
												<div className="flex row news-list">
													<p className="date">{post.frontmatter.date}</p>
													<div className="tag">
														<p className={`tag tag-exhibition`}>{events}</p>
													</div>
												</div>
												<p className="post-title">{post.frontmatter.title}</p>
											</li>
										</LocalizedLink>
									) : (
										<LocalizedLink
											className={`news-link`}
											to={`/${post.parent.relativeDirectory}/`}
										>
											<li
												key={`${post.frontmatter.title}-${post.fields.locale}`}
											>
												<div className="flex row news-list">
													<p className="date">{post.frontmatter.date}</p>
													<div className="tag">
														{(() => {
															switch (post.frontmatter.tag) {
																case "newProduct":
																	return (
																		<p className={`tag tag-new-product`}>
																			{newProducts}
																		</p>
																	);
																case "campaign":
																	return (
																		<p className={`tag tag-campaign`}>
																			{campaign}
																		</p>
																	);
																case "product":
																	return (
																		<p className={`tag tag-product`}>
																			{products}
																		</p>
																	);
																case "exhibition":
																	return (
																		<p className={`tag tag-exhibition`}>
																			{events}
																		</p>
																	);
																case "notice":
																	return (
																		<p className={`tag tag-notice`}>{notice}</p>
																	);
																default:
																	return (
																		<p className={`tag tag-general`}>
																			{general}
																		</p>
																	);
															}
														})()}
													</div>
												</div>
												<p className="post-title">{post.frontmatter.title}</p>
											</li>
										</LocalizedLink>
									)}
								</>
							))}
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default News;

export const query = graphql`
	query News($locale: String!, $dateFormat: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(sato-corporate-blog/blog)/.*\\\\.mdx$/" }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					frontmatter {
						title
						date(formatString: $dateFormat)
						featuredImage
						tag
					}
					fields {
						locale
					}
					parent {
						... on File {
							relativeDirectory
						}
					}
				}
			}
		}
	}
`;
